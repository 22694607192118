*{
    transition: all 0.2s ease;
}

body{
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
}

pre{
    line-height: 1.5em;
    font-size: 1rem;
    font-family: inherit;
}
.index-counter{
    text-align: right;
    font-size: 14px;
}
.hover-pointer{
    cursor: pointer;
}
.fill{
    background: transparent;
    width: 100%;
    height: 100%;
}
.box-reset{
    border-style: none;
}
.bg-none{
    background: transparent;
}
.bg-black{
    background:black !important;
}
.bg-blackpearl{
    background: #07070A;
}
.bg-twitterblue{
    background: #00acee !important;
}
.bg-error{
    background-color: #c0392b !important;
}
.bg-green{
    background: #27ae60;
}
.bg-lightgrey{
    background: #232228;
}
.bg-yellow{
    background: #f1c40f !important;
}
.bg-purple{
    background: #5f27cd !important;
}
.bg-dmode-blue{
    background: #222f3e !important;
}
.bg-white{
    background: white;
}
.bg-transparent{
    background: transparent;
}
.bg-lightergrey{
    background-color: #6A6A6A !important;
}
.text-grey{
    color: #C4C4C4;
}
.text-fadegrey{
    color: #5F5F5F;
}
.text-white{
    color: white;
}
.text-orange{
    color: #e74c3c;
}
.text-twitterblue{
    color: #00acee !important;
}
.text-black{
    color: black;
}
.shadow{
    box-shadow: 0px 4px 150px rgba(0, 0, 0, 0.3);
}
.shadow-strong{
    box-shadow: 0px 4px 150px rgba(0, 0, 0, 0.568);
}
a{
    text-decoration: none;
}
.rounded{
    border-radius: 10px;
}
.box{
    box-sizing: border-box;
}
.border-grey{
    border: 3px solid #7C7C7C !important;
    box-sizing: border-box;
}
.border-lightgrey{
    border: 2px solid #4D4D4D;
    box-sizing: border-box;
}
.border-white{
    border: 1px solid white;
    box-sizing: border-box;
}
.border-orange{
    border: 3px solid #5f27cd;
    box-sizing: border-box;
}
.border-width-1{
    border-width: 1px;
}
.border-none{
    border-style: none;
}
.page-container{
    min-height: 100vh;
}
.container{
    margin: auto;
    width: 70%;
    padding: 20px;
}
.sidebar{
    padding: 40px;
    min-width: 200px;
}
.main-bar{
    box-sizing: border-box;
    padding: 40px;
}
.fullspan{
    width: 100%;
}
.space-reset{
    padding: 0;
    margin: 0;
}
.pad-1{
    padding: 10px !important;
}
.pad-lr-2{
    padding-left: 20px;
    padding-right: 20px;
}
.pad-2{
    padding: 20px;
}
.pad-3{
    padding: 30px;
}
.flex{
    display: flex;
}
.m-0{
    margin: 0px;
}
.mx-auto{
    margin: auto;
}
.mt-1{
    margin-top: 10px;
}
.mt-2{
    margin-top: 20px;
}
.ml-1{
    margin-left: 5px;
}
.mr-1{
    margin-right: 5px;
}
.ml-2{
    margin-left: 15px;
}
.mr-2{
    margin-right: 15px;
}
.mb-1{
    margin-bottom: 10px;
}
.mb-2{
    margin-bottom: 20px;
}
.pt-1{
    padding-top: 10px;
}
.pb-1{
    padding-bottom: 10px;
}
.pb-0{
    padding-bottom: 0;
}
.pt-2{
    padding-top: 20px;
}
.pb-2{
    padding-bottom: 20px;
}
.pl-0{
    padding-left: 0px;
}
.flex-column{
    flex-direction: column;
}
.flex-end{
    align-self: flex-end;
}
.flex-center{
    align-items: center;
    justify-content: center;
}
.flex-align-row{
    align-items: center;
}
.flex-space-between{
    justify-content:space-between;
}
.flex-wrap{
    flex-wrap: wrap;
}
.align-right{
    text-align: right;
}
.image-box{
    height: 10vh;
    width: 18%;
    margin-right: 15px;
}
.image-background-default{
    background-size: cover;
    background-position: center;
}

.bold{
    font-weight: 700;
}
.underline{
    text-decoration: underline;
}
.text-left{
    text-align: left;
}
.text-right{
    text-align: right;
}
.text-center{
    text-align: center;
}
.border-divide-top{
    border-top: 1px darkgrey solid;
}
.border-divide-bottom{
    border-bottom: 1px grey solid;
}

pre{
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

.badge{
    font-size: 10px;
    padding: 1px 4px;
    font-weight: 600;
    border-radius: 3px;
    margin-left: auto;
}


.badge-left{
    font-size: 10px;
    padding: 1px 4px;
    font-weight: 600;
    border-radius: 3px;
}

.badge-left-large{
    font-size: 14px;
    padding: 4px 4px;
    font-weight: 600;
    border-radius: 3px;
}

.badge-img{
    width: 20px;
    border-radius: 50%;
}
.m-auto{
    margin: 0;
}

/* CUSTOM STYLES */

.true-side-menu{
    box-sizing: border-box;
    z-index: 2;
    position: fixed;
    max-height:100vh;
    min-width: 280px;
    overflow-y: scroll;
}

#tweet-box{
    color: #7C7C7C;
    outline: none;
}

#tweet-box:focus-within{
    color:white;
}
.depth-container-dark{
    background: #23262F;
    box-shadow: inset 0px 4px 20px 10px rgba(0, 0, 0, 0.25);
    border-radius: 23px;
}
.tweet-boxes{
    width: 100%;
}

.menu{
    margin-top: 20px;
}
.menu-items{
    padding: 10px;
    margin-top: 20px;
}
.menu-hamburger{
    display: none;;
    padding-left: 5px;
}
.menu-icons{
    padding-right: 10px;
}

.menu-items--selected{
    background-color:#5f27cd;
}

.menu-items--dark{
    background-color:#3d3d3d;
}

.user{
    flex-shrink: 0;
    width: 40px  !important;
    height: 40px !important;
    border-radius: 50%;
}

.profile-image{
    flex-shrink: 0;
    width: 60px  !important;
    height: 60px !important;
    border-radius: 50%;
}

.account-icons{
    position: relative;
    z-index: 1 !important;
    margin-right: 10px;
}

.left-corner-link{
    position: absolute; 
    left: 20px; 
    top: 20px;
}
.login-form{
    background: none; 
    border-style: none; 
    width: 100%; 
    height: 100%; 
    font-size: 16px;
}
.login-image{
    min-height: 20vh;
    backdrop-filter:brightness(30%);
}
.login-image-credit{
    font-size: 11px;
}
.login-container{
    width: 30vw;
}
.login-input-width{
    width: 90%;
}

span{
    outline: hidden;
}
.dim{
    opacity: 0.3;
}
.days-list{
    padding: 0;
    list-style-type: none;
    margin: 0;
}
.timelist-elem:hover{
    text-decoration: line-through;
    cursor: pointer;
}
.skeleton-container{
    position: relative;
    overflow: hidden;
}

.skeleton-container::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 60%;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(48,48,48,1) 49%, rgba(255,255,255,0) 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}

.tweet-preview-col{
    min-width: 30vw;
}

.desktop-overflow{
    overflow-y: scroll;
    max-height: 100vh;
}

.desktop-overflow-overlay{
    max-height: 100vh;
    overflow-y: scroll;
}

.overlay-bar{
    box-sizing:border-box;
    width: 25vw;
    height: 100vh;
}
.reset-button{
    margin-left: 10px;
}
.tweet-grid-box{
    min-width: 23vw;
    max-width: 30%;
    flex: auto; /* default value */
    margin: 15px;
}

.inspo-nudge-bar{
    min-width: 25vw !important;
    height: 100vh !important; 
}

.nodisplay{
    display: none;
}

/* TWEET LAYOUT STOLEN */

.avator {
    border-radius:100px;
    width:3vw;
    margin-right: 15px;
  }
  
  .tweet-wrap {
    width: 100%;
    box-sizing:border-box;
    margin: 0 auto;
    padding: 20px;
    overflow: hidden;
    border:grey 0.5px solid;
  }
  
  .tweet-header {
    display: flex;
    align-items:flex-start;
    font-size:14px;
  }
  .tweet-header-info {
    font-weight:bold;
  }
  .tweet-header-info span {
    color:#657786;
    font-weight:normal;
    margin-left: 5px;
  }
  .tweet-header-info p {
    font-weight:normal;
    margin-top: 5px;
    
  }
  .tweet-img-wrap {
    padding-left: 60px;
  }
  
  .tweet-info-counts {
    display: flex;
    margin-left: 60px;
    margin-top: 10px;
  }
  .tweet-info-counts div {
    display: flex;
    margin-right: 20px;
  }
  .tweet-info-counts div svg {
    color:#657786;
    margin-right: 10px;
  }
  .tweet-preview-col{
      max-width: 20vw;
  }
  @media screen and (max-width:430px){
    body {
      padding-left: 20px;
      padding-right: 20px;
    }
    .tweet-header {
      flex-direction:column;
    }
    .tweet-header img {
      margin-bottom: 20px;
    }
    .tweet-header-info p {
      margin-bottom: 30px;
    }
    .tweet-img-wrap {
      padding-left: 0;
    }
    .tweet-info-counts {
    display: flex;
    margin-left: 0;
  }
  .tweet-info-counts div {
    margin-right: 10px;
  }
  }


/* TOGGLE */

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #5f27cd;
}

input:focus + .slider {
  box-shadow: 0 0 1px #5f27cd;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


/* SCROLLBAR */

/* width */
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

@keyframes load {
    from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
}
/* Breakpoints */

@media (max-width: 1000px) {
    .inspo-nudge-bar{
        display: none !important;
    }
    .tweet-grid{
        box-sizing: border-box;
        padding: 3vh;
        flex-direction: column;
        
    }
    .tweet-grid-box{
        max-width: 100%;
        margin: 10px 0px;
        flex-basis: 100%;
    }
    .schedule-buttons{
        width: 100%;
        flex-direction: column;
    }
    .badge-left-large{
        /* transform: scale(0.6); */
        font-size: 11px;
        padding: 5px !important;
    }
    .overlay-bar{
        width: 100vw;
        height: 100vh;
    }
    .menu-hamburger{
        display: block;;
    }
    .menu{
        display: none;
    }
    .main-bar{
        padding: 0px;
    }
    .page-container{
        flex-direction: column;
    }
    .sidebar{
        min-height: 25px;
        padding: 15px 20px;
        margin-bottom: 10px !important;
    }
    .true-side-menu{
        margin: 0 !important;
        position: fixed; 
        min-height:auto;
        min-width: 100%;
        box-sizing: border-box;
    }
    .container {
      width: 90%;
    }
    .image-box{
        height: 10vh;
        width: 18%;
    }
    .mobile-visible{
        display: block;
    }
    .true-side-menu-visible{
        background: #222f3e !important;
    }
    .menu-items{
        margin-top: 10px;
    }
    .login-container{
        width: 70vw;
    }
    .schedule-table{
        flex-direction: column;
    }
    .picker-container{
        flex-direction: column;
    }
    .schedule-button{
        width: 100%;
        margin-top: 10px;
        font-size: 12px;
    }
    .reset-button{
        width: 100%;
        margin-top: 10px;
        margin-left: 0px;
        font-size: 12px;
    }
    .rt-submit{
        width: 100%;
        margin-top: 10px;
        font-size: 12px;
    }
    .tweet-preview-col{
        display: none;
    }
    .desktop-overflow{
        box-sizing: border-box;
        overflow:visible;
        max-height: 100%;
    }
    .desktop-overflow-overlay{
        box-sizing: border-box;
        overflow:scroll;
        max-height: 100%;
    }
}

@media (max-width: 450px) {
    .schedule-buttons{
        width: 100%;
        flex-direction: column;
    }
    body{
        font-size: 14px;
        margin: 0px;
        padding: 0px;
    }
    button{
        font-size: 10px;
    }
    .container{
        width: 100%;
        margin: 0px;
        padding: 0px;
    }
    .image-box{
        height: 10vh;
        width: 10vh;
        margin-right: 5px;
    }
    .pad-2{
        padding: 20px;
    }
    .mt-1{
        margin-top: 10px;
    }
    .mb-2{
        margin-bottom: 10px;
    }
    .tweet-boxes{
        padding: 10px;
        width: 100%;
    }
    .image-icon{
        width: 8vw;
    }
    .sidebar{
        margin-bottom: 0px;
    }
    .sidebar .logo{
        width: 20px;
    }
    #tweets{
        margin: 0px;;
    }
    .account-row{
        max-width: 100vw;
        padding-bottom: 10px;
    }
    .extra-upload{
        margin-top:15px;
        justify-content: center;
        align-items: center;
    }
    .account-icons{
        z-index: 1;
        margin-right: 0px;
        margin-left: 5px;
    }
    .upload-section-icons{
        padding-right: 5px;
        width: 20px;
    }
    .login-container{
        width: 100vw;
    }
    .left-corner-link{
        font-size: 10px;
        left:5px;
        top:5px;
    }
    .login-image-credit{
        margin-right: 10px;
    }
    #tweet-buttons{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .btn-text{
        width: 100%;
        margin-bottom: 1vh;
    }
    .account-container{
        margin: auto;
        width: 90%;
    }
    .picker-container{
        flex-direction: column;
    }
    .schedule-button{
        width: 100%;
        margin-top: 10px;
        font-size: 12px;
    }
    .reset-button{
        width: 100%;
        margin-top: 10px;
        font-size: 12px;
        margin-left: 0px;
    }
    .tweet-preview-col-visible{
        display: block;
        position: absolute;
        width: 100%;
        min-height: 100vh;
    }
}

/* LOADER */

.loader {
    color: #ffffff;
    font-size: 50px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 30px auto;
    position: relative;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
    animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  }
  @-webkit-keyframes load6 {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  @keyframes load6 {
    0% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
      box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
      box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
  }
  @-webkit-keyframes round {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes round {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  

  .loader-2 { 
    width:80%; 
    margin:0 auto;
    border-radius:10px;
    border:4px solid transparent;
    position:relative;
    padding:1px;
  }
  .loader-2:before {
    content:'';
    border:1px solid #fff; 
    border-radius:10px;
    position:absolute;
    top:-4px; 
    right:-4px; 
    bottom:-4px; 
    left:-4px;
  }
  .loader-2 .loaderBar { 
    position:absolute;
    border-radius:10px;
    top:0;
    right:100%;
    bottom:0;
    left:0;
    background:#fff; 
    width:0;
    animation:borealisBar 2s linear infinite;
  }
  
  @keyframes borealisBar {
    0% {
      left:0%;
      right:100%;
      width:0%;
    }
    10% {
      left:0%;
      right:75%;
      width:25%;
    }
    90% {
      right:0%;
      left:75%;
      width:25%;
    }
    100% {
      left:100%;
      right:0%;
      width:0%;
    }
  }

  .loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #e74c3c; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 700ms linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }